import {
  Box,
  Button,
  Card,
  Flex,
  Group,
  LoadingOverlay,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { useLogin } from "modules/auth/hooks/useLogin";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "redux/actionTypes";
import http from "services/http";
import { setSessionAccess } from "services/store";

const Login = () => {
  const { isLogin, login } = useLogin();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      username: "",
      password: "",
    },

    validate: {
      username: isNotEmpty(""),
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: isNotEmpty(""),
    },
  });

  const onSubmitBtn = async () => {
    try {
      setLoading(true);
      const res = await http.post("/auth/login", {
        username: form.getInputProps("username").defaultValue,
        password: form.getInputProps("password").defaultValue,
      });
      const data: any = res?.data?.data;

      dispatch({
        type: "LOGIN",
        payload: {
          isAuth: true,
        },
      });
      setSessionAccess(data?.accessToken);
      navigate("/");
      console.log("res: ", res);
    } catch (error: any) {
      notifications.show({
        color: "red",
        title: error.data?.message,
        message: null,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      className="absolute w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        // className="bg-cover bg-no-repeat bg-[50%] bg-[url('https://hr.onecruit.uz/static/media/login_bg.dc5f0101e0d66bb0eee2.jpg')]"
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          data-aos="flip-right"
          w={400}
          shadow="lg"
          radius={"md"}
          className="relative px-10 py-7"
        >
          <LoadingOverlay
            visible={isLogin}
            zIndex={1000}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <Flex align={"center"} className="mb-3" justify={"center"}>
            <h2 className="text-2xl font-bold">Login</h2>
          </Flex>
          <form>
            <Flex
              gap="md"
              justify="flex-start"
              align="flex-start"
              direction="column"
              wrap="wrap"
            >
              <TextInput
                name="username"
                className="w-full"
                label="Username"
                type="text"
                placeholder="Please enter your username."
                {...form.getInputProps("username")}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    onSubmitBtn();
                  }
                }}
              />

              <TextInput
                name="password"
                className="w-full"
                label="Password"
                type="password"
                placeholder="Please enter your password."
                {...form.getInputProps("password")}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    onSubmitBtn();
                  }
                }}
              />
            </Flex>

            <Group justify="flex-end" mt="md">
              <Button onClick={onSubmitBtn} fullWidth loading={loading}>
                Login
              </Button>
            </Group>
          </form>
        </Card>
      </div>
    </motion.div>
  );
};

export default Login;
