import Dashboard from "pages/Private/Dashboard";
import Forecast from "pages/Private/Forecast";
import News from "pages/Private/News";
import Insights from "pages/Private/Sportmen";
import Search from "pages/Private/Sports";
import Login from "pages/Public/Auth/Login";
import TestComponent from "test";

interface Routes {
  key: string;
  name: string;
  path: string;
  component: React.FC;
  children?: {
    key: string;
    name: string;
    path: string;
    component: React.FC;
  }[];
  config: {
    isAuth: boolean;
  };
}

export const routes: Routes[] = [
  {
    key: "login",
    name: "Login",
    path: "/",
    component: Login,
    config: {
      isAuth: false,
    },
  },
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/",
    component: Dashboard,
    config: {
      isAuth: true,
    },
  },
  {
    key: "search",
    name: "Search",
    path: "/category",
    component: Search,
    config: {
      isAuth: true,
    },
  },
  {
    key: "insights",
    name: "Insights",
    path: "/insights",
    component: Insights,
    config: {
      isAuth: true,
    },
  },
  {
    key: "docs",
    name: "Docs",
    path: "/forecast",
    component: Forecast,
    config: {
      isAuth: true,
    },
  },
  {
    key: "news",
    name: "News",
    path: "/news",
    component: News,
    config: {
      isAuth: true,
    },
  },
  {
    key: "docs",
    name: "Docs",
    path: "/test",
    component: TestComponent,
    config: {
      isAuth: true,
    },
  },
];
