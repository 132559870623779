import { Avatar, Box, Button, Flex, Table, Text } from "@mantine/core";
import config from "config";
import dayjs from "dayjs";
import { Sports } from "modules/news/types";
import { sportTypeConverter } from "utils/sportTypeConverter";

const elements = [
  { position: 6, symbol: "C", name: "Carbon" },
  { position: 7, symbol: "N", name: "Nitrogen" },
  { position: 39, symbol: "Y", name: "Yttrium" },
  { position: 56, symbol: "Ba", name: "Barium" },
  { position: 58, symbol: "Ce", name: "Cerium" },
];

interface Props {
  sports: Sports[] | undefined;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
}

const TableArticles = (props: Props) => {
  const { sports, handleEdit, handleDelete } = props;

  const rows = sports?.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td>
        <Box className="text-center flex justify-center">
          <Avatar
            src={
              element?.photoPath
                ? `${config.api.baseURL}/storage/files/${element.photoPath}`
                : null
            }
            className="cursor-pointer"
            size={"md"}
          />
        </Box>
      </Table.Td>
      <Table.Td className="text-center">{element.name}</Table.Td>

      <Table.Td className="text-center">
        {dayjs(element.createdAt).format("DD-MM-YYYY / HH:mm:ss")}
      </Table.Td>
      <Table.Td>
        <Flex justify={"end"}>
          <Button
            mr={10}
            variant="light"
            onClick={() => handleEdit(element.id)}
          >
            Edit
          </Button>
          <Button
            variant="light"
            color="red"
            onClick={() => handleDelete(element.id)}
          >
            Delete
          </Button>
        </Flex>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Table
      withColumnBorders
      horizontalSpacing={"md"}
      striped
      highlightOnHover
      withTableBorder
    >
      <Table.Thead>
        <Table.Tr>
          <Table.Th className="text-center" w={50}>
            Photo
          </Table.Th>
          <Table.Th className="text-center">Name</Table.Th>
          <Table.Th className="text-center">Created date</Table.Th>
          <Table.Th w={100} className="text-center">
            Actions
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
    </Table>
  );
};

export default TableArticles;
