import AOS from "aos";
import { AnimatePresence } from "framer-motion";
import PageNotFound from "pages/Public/NotFound";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { getPrivateRoutes, getPublicRoutes } from "route/config";
import { clearSession, getSession } from "services/store";
import AppLayout from "ui/AppLayout/AppLayout";
import CustomComponent from "ui/CustomComponent";
import Loader from "ui/Loader";
import { SET_USER_DATA } from "./redux/actionTypes";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const { isAuth, userData } = useSelector((state: any) => state.authReducer);

  const token = getSession();

  // Init AOS
  useEffect(() => {
    AOS.init();
  }, []);

  // Token Expiration Check
  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true);
          // let resp = await getAboutMe();
          dispatch({
            type: SET_USER_DATA,
            payload: { isAuth: true, data: { name: "Sport" } },
          });
        } catch (error: any) {
          if (error?.status === 401) {
            // clearSession();
          }
        } finally {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    })();
  }, []);

  // Loader
  if (loading)
    return (
      <div className="w-[100%] h-[100vh] flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        {isAuth ? (
          <Fragment>
            {/* App routes */}
            <Route path="/" element={<AppLayout />}>
              {getPrivateRoutes().map((item, i) => (
                <Route
                  key={item.key}
                  path={`${item.path}`}
                  element={<CustomComponent Component={item.component} />}
                />
              ))}
            </Route>
          </Fragment>
        ) : (
          <Fragment>
            {/* Public routes */}

            {/* Routes for single pages without auth */}
            {getPublicRoutes().map((item, i) => (
              <Route
                key={item.key}
                path={`${item.path}`}
                element={<CustomComponent Component={item.component} />}
              />
            ))}
          </Fragment>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default App;
