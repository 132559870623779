import {
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  ComboboxItem,
  Divider,
  Flex,
  Group,
  Select,
  Skeleton,
  Text,
  UnstyledButton,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useColorScheme, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { MantineLogo } from "@mantinex/mantine-logo";
import {
  Bolt,
  LayoutDashboard,
  LogOut,
  Medal,
  MessageCircle,
  Newspaper,
  SlidersHorizontal,
  SunMoon,
  Users,
} from "lucide-react";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { LOG_OUT } from "../../redux/actionTypes";
import { motion } from "framer-motion";
// import "./MobileNavbar.css";

const AppLayout = () => {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const colorScheme = useColorScheme();
  // const [value, setValue] = useState<string>(colorScheme);
  const { colorScheme, setColorScheme, clearColorScheme } =
    useMantineColorScheme();
  // console.log("🚀 ~ AppLayout ~ aaaaa:", aaaaa);
  const handleLogout = () => {
    modals.openConfirmModal({
      title: "Please confirm your action.",
      children: <Text size="md">Do you want to log out?</Text>,
      labels: { confirm: "Yes", cancel: "No" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        dispatch({
          type: LOG_OUT,
        });
        navigate("/");
      },
    });
  };

  const themeHandler = (value: "auto" | "light" | "dark") => {
    console.log("🚀 ~ themeHandler ~ value:", value);
    setColorScheme(value);
  };

  return (
    <Fragment>
      <motion.div
        className="absolute w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <AppShell
          header={{ height: 70 }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
          }}
          padding="md"
        >
          <AppShell.Header>
            <Group h="100%" px="md">
              {/* <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm"
            size="sm"
          /> */}
              <Group justify="space-between" style={{ flex: 1 }}>
                <Flex align={"center"}>
                  <Burger
                    opened={mobileOpened}
                    onClick={toggleMobile}
                    hiddenFrom="sm"
                    size="sm"
                  />
                  <Burger
                    opened={desktopOpened}
                    onClick={toggleDesktop}
                    visibleFrom="sm"
                    size="sm"
                  />
                  <NavLink to={"/"} className="flex items-center w-full px-3">
                    <svg
                      className="w-8 h-8 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                    </svg>
                    <span className="ml-2 text-sm font-bold">
                      Qurilish express
                    </span>
                  </NavLink>
                  {/* <MantineLogo size={30}>a</MantineLogo> */}
                </Flex>
                <Group ml="xl" gap={0} className="py-4">
                  <Select
                    className="w-[120px] mr-3"
                    data={[
                      { value: "light", label: "Light" },
                      { value: "dark", label: "Dark" },
                      { value: "auto", label: "Systen" },
                    ]}
                    leftSection={<SunMoon size={18} />}
                    value={colorScheme}
                    onChange={(_value: any) => themeHandler(_value)}
                  />
                  <Avatar
                    size="md"
                    className="cursor-pointer"
                    // src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png"
                  />
                </Group>
              </Group>
            </Group>
          </AppShell.Header>
          <AppShell.Navbar p={5}>
            <div className="flex flex-col items-center w-full h-full overflow-hidden   rounded">
              {/* <a className="flex items-center w-full px-3 mt-3" href="#">
            <svg
              className="w-8 h-8 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
            </svg>
            <span className="ml-2 text-sm font-bold">The App</span>
          </a> */}
              <div className="w-full px-2">
                <div className="flex flex-col items-center w-full ">
                  <NavLink
                    to={"/"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <LayoutDashboard size={24} />
                    <span className="ml-2 text-sm font-medium">Dashboard</span>
                  </NavLink>
                  <NavLink
                    to={"/category"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <SlidersHorizontal size={24} />
                    <span className="ml-2 text-sm font-medium">Category</span>
                  </NavLink>
                  {/* <NavLink
                    to={"/insights"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2  rounded"
                  >
                    <Users size={24} />
                    <span className="ml-2 text-sm font-medium">
                      Sportchilar
                    </span>
                  </NavLink>
                  <NavLink
                    to={"/forecast"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <MessageCircle size={24} />
                    <span className="ml-2 text-sm font-medium">Bashorat</span>
                  </NavLink>
                  <NavLink
                    to={"/news"}
                    className="myNavLin flex items-center w-full h-12 px-3 mt-2 rounded "
                  >
                    <Newspaper size={24} />
                    <span className="ml-2 text-sm font-medium">
                      Maqola va yangiliklar
                    </span>
                  </NavLink> */}
                </div>
              </div>
              <Box className="mt-auto w-full pb-2 px-2">
                <Button
                  className="shadow-md"
                  onClick={handleLogout}
                  leftSection={<LogOut size={14} />}
                  variant="light"
                  color="red"
                  fullWidth
                >
                  Log out
                </Button>
              </Box>
              {/* <a className="flex items-center justify-center w-full h-16 mt-auto bg-gray-200 hover:bg-gray-300">
            <svg
              className="w-6 h-6 stroke-current"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="ml-2 text-sm font-medium">Log out</span>
          </a> */}
            </div>
          </AppShell.Navbar>
          <AppShell.Main className="overflow-hidden relative">
            <Outlet />
          </AppShell.Main>
        </AppShell>
      </motion.div>
    </Fragment>
  );
};

export default AppLayout;
